<template>
  <v-card>
    <v-card-text>
      <div class="d-flex justify-space-between test2">
        <div class="test">
          <!-- all page -->
          <button
            @click="OrderStatus = ''"
          >
            <div
              class="me-2 box_content"
              :class="{'me-2 box_content  active': OrderStatus === ''}"
            >
              <p class="mb-0">
                {{ all ? all : 0 }}
              </p>
              <p class="mb-0">
                ทั้งหมด
              </p>
            </div>
          </button>
          <!-- draft page -->
          <!-- <button @click="gotoDraft()">
            <div class="me-2 box_content">
              <p class="mb-0">
                {{ 0 }}
              </p>
              <p class="mb-0">
                ร่าง
              </p>
            </div>
          </button> -->
          <!-- not paid -->
          <button
            @click="OrderStatus = 'PT'"
          >
            <div
              class="me-2 box_content"
              :class="{'me-2 box_content  active': OrderStatus === 'PT'}"
            >
              <p class="mb-0">
                {{ OrderStatusPending ? OrderStatusPending.OrderStatusPendingTransfer : 0 }}
              </p>
              <p class="mb-0">
                รอชำระเงิน
              </p>
            </div>
          </button>
          <!-- paid -->
          <button

            @click="OrderStatus = 'PC'"
          >
            <div
              class="me-2 box_content"
              :class="{'me-2 box_content  active': OrderStatus === 'PC'}"
            >
              <p class="mb-0">
                {{ OrderStatusPending ? OrderStatusPending.OrderStatusPendingConfirm : 0 }}
              </p>
              <p class="mb-0">
                ตรวจสอบ
              </p>
            </div>
          </button>
          <!-- MakeSend -->
          <button

            @click="OrderStatus = 'PP'"
          >
            <div
              class="me-2 box_content"
              :class="{'me-2 box_content  active': OrderStatus === 'PP'}"
            >
              <p class="mb-0">
                {{ OrderStatusPending ? OrderStatusPending.OrderStatusPendingProduct : 0 }}
              </p>
              <p class="mb-0">
                กำลังผลิต
              </p>
            </div>
          </button>
          <button
            @click="OrderStatus = 'PTP'"
          >
            <div
              class="me-2 box_content"
              :class="{'me-2 box_content  active': OrderStatus === 'PTP'}"
            >
              <p class="mb-0">
                {{ OrderStatusPending ? OrderStatusPending.OrderStatusPendingTransport : 0 }}
              </p>
              <p class="mb-0">
                เตรียมจัดส่ง
              </p>
            </div>
          </button>
          <!-- Send -->
          <button
            @click="OrderStatus = 'F'"
          >
            <div
              class="me-2 box_content"
              :class="{'me-2 box_content  active': OrderStatus === 'F'}"
            >
              <p class="mb-0">
                {{ OrderStatusPending ? OrderStatusPending.OrderStatusPendingFinished : 0 }}
              </p>
              <p class="mb-0">
                ส่งแล้ว
              </p>
            </div>
          </button>
          <!-- Expire -->
          <!-- <button @click="gotoExpire()">
            <div class="me-2 box_content">
              <p class="mb-0">
                0
              </p>
              <p class="mb-0">
                หมดอายุ
              </p>
            </div>
          </button> -->
          <!-- Cancel -->
          <button
            @click="OrderStatus = 'C'"
          >
            <div
              class="me-2 box_content"
              :class="{'me-2 box_content  active': OrderStatus === 'C'}"
            >
              <p class="mb-0">
                {{ OrderStatusPending ? OrderStatusPending.OrderStatusPendingCanceled : 0 }}
              </p>
              <p class="mb-0">
                ยกเลิก
              </p>
            </div>
          </button>
        </div>
        <div class="d-flex align-center">
          <v-dialog
            v-if="$store.state.user && $store.state.user.member_id"
            transition="dialog-top-transition"
            width="1200"
          >
            <template v-slot:activator="{ on, attrs }">
<!--              v-on="on"-->
              <v-btn
                color="primary"
                v-bind="attrs"
                @click="customerOpen()"
              >
                <v-icon class="me-2">
                  {{ icons.mdiCartMinus }}
                </v-icon>
                เปิดบิล
              </v-btn>
            </template>
            <template>
              <v-card>
                <CreateBill/>
              </v-card>
            </template>
          </v-dialog>

          <v-dialog
            v-if="$store.state.user && !$store.state.user.member_id"
            transition="dialog-top-transition"
            width="600"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                v-bind="attrs"
                class="me-2 mg-res"
                v-on="on"
              >
                <v-icon class="me-2">
                  {{ icons.mdiCartMinus }}
                </v-icon>
                เปิดบิล
              </v-btn>
            </template>
            <template>
              <v-card>
                <v-card-text class="pb-0 pt-5 bg-header">
                  <div class="d-flex justify-space-between">
                    <p>เปิดบิลใหม่</p>
                    <span
                      v-if="dataOpenUser === false"
                      disabled
                      @click="switchOpenUser('')"
                    >เปิดบิลจากลูกค้ารายใหม่</span>
                    <span
                      v-if="dataOpenUser === true"
                      @click="switchOpenUser('')"
                    >เปิดบิลจากลูกค้าปัจจุบัน</span>
                  </div>
                </v-card-text>
                <!-- Content import -->
                <OpenBill v-if="dataOpenUser === false" :switchOpenUser="switchOpenUser"
                          :name="name"/>
                <OpenNewUser v-if="dataOpenUser === true" :switchOpenUser="switchOpenUser"/>
                <!-- <v-card-text>
                  <v-btn color="primary" @click="dialog.value = false" class="me-2">เปิดใช้งาน</v-btn>
                  <v-btn color="error" outlined @click="dialog.value = false">เรียนรู้เพิ่มเติม</v-btn>
                </v-card-text> -->
                <!-- <v-card-actions class="justify-end">
                  <v-btn text @click="dialog.value = false">Close</v-btn>
                </v-card-actions> -->
              </v-card>
            </template>
          </v-dialog>
        </div>
      </div>
      <v-row class="mt-5">
        <v-col
          md="4"
          sm="6"
          cols="12"
        >
          <v-text-field
            v-model="search"
            :prepend-inner-icon="icons.mdiMagnify"
            label="ค้นหาเลขที่บิล"
            type="text"
            outlined
            dense
            hide-details
            placeholder="ค้นหา"
            @blur="getOrders()"
          ></v-text-field>
        </v-col>
        <v-col
          md="4"
          sm="6"
          cols="12"
        >
          <v-select
            :prepend-inner-icon="icons.mdiTruck"
            dense
            outlined
            hide-details
            label="เฉพาะขนส่ง"
            item-text="transport_type_name"
            item-value="transport_type_id"
            :items="transport"
            disabled
          ></v-select>
        </v-col>
        <v-col
          md="4"
          sm="6"
          cols="12"
        >
          <v-select
            :prepend-inner-icon="icons.mdiPrinterSearch"
            dense
            hide-details
            outlined
            disabled
            label="กรองตามสถานะการพิมพ์"
            :items="statusFilter"
          ></v-select>
        </v-col>
        <v-col
          md="4"
          sm="6"
          cols="12"
        >
          <div>
            <DatePicker
              range
              multi-calendars
              value-type="format"
              format="YYYY-MM-DD"
              placeholder="เลือกเฉพาะวันที่"
              @change="getOrders()"
              style="width: 100%"
              v-model="date"
            ></DatePicker>
          </div>
        </v-col>
        <v-col
          md="8"
          sm="6"
          cols="12"
        >
          <v-row>
            <!--            <v-col-->
            <!--              md="3"-->
            <!--              cols="12"-->
            <!--            >-->
            <!--              <v-select-->
            <!--                v-model="status"-->
            <!--                dense-->
            <!--                hide-details-->
            <!--                outlined-->
            <!--                item-text="label"-->
            <!--                item-value="value"-->
            <!--                label="เปลี่ยนสถานะ"-->
            <!--                :items="StatusOrder"-->
            <!--                @change="getOrders()"-->
            <!--              ></v-select>-->
            <!--            </v-col>-->
            <v-col
              md="6"
              cols="12"
            >
              <v-select
                dense
                hide-details
                outlined
                label="พิมพ์"
                :items="print"
                disabled
              ></v-select>
            </v-col>
            <v-col
              md="3"
              cols="12"
            >
              <v-select
                dense
                hide-details
                outlined
                label="ส่งสินค้า"
                :items="transportProduct"
                disabled
              ></v-select>
            </v-col>
            <v-col
              md="3"
              cols="12"
            >
              <v-select
                dense
                hide-details
                outlined
                label="ดาวน์โหลด"
                :items="download"
                disabled
              ></v-select>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <!-- ตารางสถานะต่าง ๆ -->

      <!--  -->
      <v-data-table
        v-model="selected"
        :search="search"
        :headers="headers"
        :items="orders"
        :server-items-length="total"
        :options.sync="serverOptions"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        item-key="order_id"
        class="elevation-1 mt-7"
      >

        <template v-slot:item.order_inv="{ item }">
          <router-link v-if="!$store.state.user.member_id"
                       :to="{ name : 'pages-order-detail' ,params :{ id : item.order_id }}">
            {{ item.order_inv }}
          </router-link>

          <router-link v-if="$store.state.user.member_id"
                       :to="{ name : 'pages-order-detail' ,params :{ id : item.order_id }}">
            {{ item.order_inv }}
          </router-link>
<!--          <span v-if="$store.state.user.member_id">{{ item.order_inv }}</span>-->
        </template>
        /
        <template v-slot:item.order_status="{ item }">
          <span v-if="item.order_status === 'PT'">รอชำระเงิน</span>
          <span v-if="item.order_status === 'PC'">รอตรวจสอบ</span>

          <span v-if="item.order_status === 'PTP'">เตรียมจัดส่ง</span>
          <span v-if="item.order_status === 'PP'">กำลังผลิต</span>

          <span v-if="item.order_status === 'F'">เสร็จสิ้น</span>
          <span v-if="item.order_status === 'C'">ยกเลิก</span>
          <!--          {{item.order_status}}-->
        </template>
        /
        <template v-slot:item.actions="{ item }">
          <v-icon
            small
            v-if="!$store.state.user.member_id"
            class="mr-2"
            @click="editItemAdmin(item)"
          >
            {{ icons.mdiPencil }}
          </v-icon>
<!--          <v-dialog-->
<!--            v-if="$store.state.user.member_id"-->
<!--            transition="dialog-top-transition"-->
<!--            width="1200">-->
<!--            <template v-slot:activator="{ on, attrs }">-->
<!--              <v-icon-->
<!--                small-->
<!--                class="mr-2"-->
<!--                v-bind="attrs"-->
<!--                v-on="on"-->
<!--                @click="editItem(item)"-->
<!--              >-->
<!--                {{ icons.mdiPencil }}-->
<!--              </v-icon>-->

<!--&lt;!&ndash;              <v-icon&ndash;&gt;-->
<!--&lt;!&ndash;                v-if="$store.state.user.member_id && (item.order_status === 'PT' || item.order_status === 'PC' )"&ndash;&gt;-->
<!--&lt;!&ndash;                small&ndash;&gt;-->
<!--&lt;!&ndash;                class="mr-2"&ndash;&gt;-->
<!--&lt;!&ndash;                @click="cancelOrder(item)">&ndash;&gt;-->
<!--&lt;!&ndash;                {{ icons.mdiDelete }}&ndash;&gt;-->
<!--&lt;!&ndash;              </v-icon>&ndash;&gt;-->
<!--            </template>-->
<!--            <template>-->
<!--              <v-card>-->
<!--                <CreateBill/>-->
<!--              </v-card>-->
<!--            </template>-->
<!--          </v-dialog>-->

          <v-dialog
            v-if="$store.state.user && $store.state.user.member_id"
            transition="dialog-top-transition"
            width="1200">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-if="$store.state.user.member_id && (item.order_status === 'PT' || item.order_status === 'PC' )"
                small
                class="mr-2"
                @click="cancelOrder(item)">
                {{ icons.mdiDelete }}
              </v-icon>

              <v-icon
                small
                v-if="$store.state.user.member_id && (item.order_status === 'PT' || item.order_status === 'PC' )"
                class="mr-2"
                v-bind="attrs"
                v-on="on"
                @click="editItem(item)"
              >
                {{ icons.mdiCartMinus }}
              </v-icon>

            </template>
            <template>
              <v-card flat>
                <v-card-title>
                  อัพโหลดหลักฐานการโอนชำระการสั่งซื้อ
                </v-card-title>
                <v-card-text>
                  <div v-if="$store.state.orderDetil">

                    <v-form ref="form" v-model="valid" lazy-validation>


                      <v-file-input
                        v-model="$store.state.orderDetil.PT_slip"
                        label="ไฟล์"
                      ></v-file-input>

                      <v-text-field class="form-control"
                                    type="number" min="1"
                                    v-model="$store.state.orderDetil.PT_price_amt"
                                    placeholder="จำนวนเงิน"></v-text-field>
                      <!--                  @change="uploadFile($event)"-->

<!--                      <hr class="mt-3 mb-3" />-->
                      <p class="mb-0">โอนเข้าบัญชี</p>
                      <v-select v-model="$store.state.orderDetil.PT_BankID" dense outlined hide-details label="เลือกธนาคาร" :items="bankList"
                                item-text="BankName" item-value="BankID" @change="getBankShow()" class="mt-2"></v-select>


                      <v-text-field label="วันที่โอน" v-model="$store.state.orderDetil.PT_date" type="date" outlined dense hide-details
                                    :rules="[v => !!v || 'กรุณากรอกวันที่โอน']" required placeholder="" class="mb-3 mt-3">
                      </v-text-field>
                      <!--                    <DatePicker-->
                      <!--                      class="mb-3 mt-3"-->
                      <!--                      v-model="order.PT_date"-->
                      <!--                      style="width: 100%"-->
                      <!--                      placeholder="กรุณาเลือกวันที่โอน"-->
                      <!--                      value-type="format"-->
                      <!--                      format="DD-MM-YYYY"-->
                      <!--                      label="Date"-->
                      <!--                      title="วันที่โอน"-->
                      <!--                    ></DatePicker>-->
                      <!-- จบวันที่โอน -->

                      <!-- เวลาโอน -->
                      <!-- <p class="mb-2">เวลาที่โอน:</p> -->
                      <v-text-field label="เวลาที่โอน" v-model="$store.state.orderDetil.PT_time" type="time" outlined dense hide-details
                                    :rules="[v => !!v || 'กรุณากรอกเวลาที่โอน']" required placeholder="" class="mb-2">
                      </v-text-field>
                      <!-- จบเวลาโอน -->
                      <!-- ถ้าเลือกพร้อมเพย์ส่วนนี้จะแสดง -->
<!--                      <div v-if="$store.state.orderDetil.bank">-->
<!--                        &lt;!&ndash; เลขบัญชี &ndash;&gt;-->
<!--                        <p class="mb-2 mt-3">เลขที่บัญชี: {{ $store.state.orderDetil.bank.account_number }}</p>-->
<!--                        &lt;!&ndash; ชื่อบัญชี &ndash;&gt;-->
<!--                        <p class="mb-2">ชื่อบัญชี: <span> {{ $store.state.orderDetil.bank.account_name }}</span>-->
<!--                        </p>-->
<!--                        &lt;!&ndash; เริ่มวันที่โอน &ndash;&gt;-->
<!--                        &lt;!&ndash; <p class="mb-2">-->
<!--                        วันที่โอน:-->
<!--                      </p> &ndash;&gt;-->
<!--                        &lt;!&ndash;            <v-menu ref="menu1" :close-on-content-click="false" transition="scale-transition" offset-y hide-details&ndash;&gt;-->
<!--                        &lt;!&ndash;              max-width="290px" min-width="auto">&ndash;&gt;-->
<!--                        &lt;!&ndash;              <template v-slot:activator="{ on, attrs }">&ndash;&gt;-->
<!--                        &lt;!&ndash;                <v-text-field v-model="payment.PT_date" label="Date" hint="MM/DD/YYYY format" persistent-hint&ndash;&gt;-->
<!--                        &lt;!&ndash;                  :rules="[v => !!v || 'กรุณาเลือกวันที่โอน']" required v-bind="attrs" outlined dense&ndash;&gt;-->
<!--                        &lt;!&ndash;                  @blur="date = parseDate(dateFormatted)" v-on="on"></v-text-field>&ndash;&gt;-->
<!--                        &lt;!&ndash;              </template>&ndash;&gt;-->
<!--                        &lt;!&ndash;              <v-date-picker&ndash;&gt;-->


<!--                        &lt;!&ndash;                no-title @input="menu1 = false">&ndash;&gt;-->
<!--                        &lt;!&ndash;              </v-date-picker>&ndash;&gt;-->
<!--                        &lt;!&ndash;              <DatePicker&ndash;&gt;-->
<!--                        &lt;!&ndash;                value-type="format"&ndash;&gt;-->
<!--                        &lt;!&ndash;                format="YYYY-MM-DD"&ndash;&gt;-->
<!--                        &lt;!&ndash;                label="วันที่โอน"&ndash;&gt;-->
<!--                        &lt;!&ndash;                v-model="$store.state.orderDetil.PT_date"&ndash;&gt;-->
<!--                        &lt;!&ndash;              ></DatePicker>&ndash;&gt;-->
<!--                        &lt;!&ndash;            </v-menu>&ndash;&gt;-->

<!--                        <DatePicker-->
<!--                          class="mb-3"-->
<!--                          v-model="$store.state.orderDetil.PT_date"-->
<!--                          style="width: 100%"-->
<!--                          placeholder="กรุณาเลือกวันที่โอน"-->
<!--                          value-type="format"-->
<!--                          format="DD-MM-YYYY"-->
<!--                          label="Date"-->
<!--                          title="วันที่โอน"-->
<!--                        ></DatePicker>-->
<!--                        &lt;!&ndash; จบวันที่โอน &ndash;&gt;-->

<!--                        &lt;!&ndash; เวลาโอน &ndash;&gt;-->
<!--                        &lt;!&ndash; <p class="mb-2">เวลาที่โอน:</p> &ndash;&gt;-->
<!--                        <v-text-field label="เวลาที่โอน" v-model="$store.state.orderDetil.PT_time" type="time" outlined dense hide-details-->
<!--                                      :rules="[v => !!v || 'กรุณากรอกเวลาที่โอน']" required placeholder="" class="mb-2">-->
<!--                        </v-text-field>-->
<!--                        &lt;!&ndash; จบเวลาโอน &ndash;&gt;-->

<!--                        &lt;!&ndash; ยอดโอน &ndash;&gt;-->
<!--                        &lt;!&ndash; <p class="mb-2">ยอดโอน:</p> &ndash;&gt;-->
<!--                        <v-text-field label="ยอดโอน" v-model="$store.state.orderDetil.PT_price_amt" type="number" outlined dense-->
<!--                                      :rules="[v => !!v || 'กรุณากรอกยอดที่โอน']" required hide-details placeholder="">-->
<!--                        </v-text-field>-->


<!--                        <v-file-input v-model="$store.state.orderDetil.file_slip" show-size label="แนบสลิป">-->
<!--                        </v-file-input>-->
<!--                        <p v-if="$store.state.orderDetil.PT_slip"><a :href="$store.state.orderDetil.PT_slip" target="_blank">กดดูไฟล์สลิป</a></p>-->

<!--                      </div>-->



                      <h4 style="color: red">
                        ยอด: {{$store.state.orderDetil.order_expense}}
                        <!--                    {{ parseFloat((parseInt(sub_total() + total_importune() + totalConfigs()) + parseInt(order.transport_expense_price) - parseInt(order.order_price_discount))).toFixed(2) }}-->
                        บาท
                      </h4>
                    </v-form>
                  </div>
                </v-card-text>
                <v-card-actions class="justify-end">

                  <v-btn
                    :disabled="(!$store.state.orderDetil.PT_price_amt && $store.state.orderDetil.PT_price_amt == 0)"
                    @click="saveUploadSlip"
                    color="primary"
                    text
                  >
                    อัพโหลดหลักฐาน
                  </v-btn>
                </v-card-actions>
              </v-card>
            </template>
          </v-dialog>

          <!--          <v-icon-->
          <!--            small-->
          <!--            @click="deleteItem(item)"-->
          <!--          >-->
          <!--            {{ icons.mdiDelete }}-->
          <!--          </v-icon>-->
        </template>
      </v-data-table>
      <v-dialog
        v-model="dialogDelete"
        max-width="500px">
        <v-card>
          <v-card-title>
            <span class="text-h5">ยกเลิกการจองการสั่งซื้อ</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <v-textarea
                    v-model="editedItem.C_order_note_cancel"
                    label="กรุณากรอกเหตุผล *"
                    placeholder="กรุณากรอกเหตุผล การยกเลิกการจองการสั่งซื้อ"
                  ></v-textarea>
                  <!--                <small-->
                  <!--                  class="validate-err"-->
                  <!--                  v-for="(err,index) in v$.editedItem.name.$silentErrors"-->
                  <!--                  v-if="v$.editedItem.name.$error">{{ index > 0 ? ', ' : '' }}-->
                  <!--                  {{ err.$message }}</small>-->
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              text
              @click="close"
            >
              ยกเลิก
            </v-btn>
            <v-btn
              color="primary"
              text
              @click="UpdateCancel"
            >
              ยืนยัน
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  mdiCartMinus, mdiMagnify, mdiTruck, mdiPrinterSearch, mdiPencil, mdiDelete
} from '@mdi/js'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import OpenBill from './OpenBill.vue'
import OpenNewUser from './OpenNewUser.vue'
import instance_order from '../../../services/order'
import transportType from '../../../services/transportType'
import user from '../../../services/user'
import CreateBill from '@/views/pages/bill/Process.vue'
import Swal from "sweetalert2";
import orderAPI from "@/services/order";
import store from "@/store";
import bankAPI from "@/services/bank";

export default {
  components: {DatePicker, OpenBill, OpenNewUser, CreateBill, Swal},
  setup() {
    const transport = ['ไม่มีข้อมูล']
    const statusFilter = ['ยังไม่พิมพ์ใบจ่าหน้า/ใบแพ็คของ', 'ยังไม่พิมพ์ใบเสร็จ/ใบกำกับ']
    const changeStatus = ['ร่าง', 'รอดำเนินการโอน', 'รอการตรวจสอบ', 'อยู่ระหว่างดำเนินการผลิต', 'เตรียมจัดส่ง', 'เสร็จสิ้น', 'ยกเลิก']
    const print = [
      'จ่าหน้าผู้รับ',
      'จ่าหน้าผู้รับ และผู้ส่ง',
      'ใบกำกับภาษี / ใบเสร็จรับเงิน',
      'ใบแพ๊กของ',
      'ใบแพ็กของพร้อมจ่าหน้า (ใหญ่)',
      'ใบแพ็กของพร้อมจ่าหน้า (เล็ก)',
      'ตั้งค่าหน้ากระดาษ',
    ]
    const transportProduct = [
      'นัดรับพรุ่งนี้เวลา 09:00 - 12:00 น.',
      'นัดรับพรุ่งนี้เวลา 12:00 - 15:00 น.',
      'นัดรับพรุ่งนี้เวลา 15:00 - 18:00 น.',
      'นัดรับวันจันทร์ ที่ 1 09:00 - 12:00 น.',
      'นัดรับวันจันทร์ ที่ 1 12:00 - 15:00 น.',
      'นัดรับวันจันทร์ ที่ 1 15:00 - 18:00 น.',
      'นัดรับวันอังคาร ที่ 2 09:00 - 12:00 น.',
      'นัดรับวันอังคาร ที่ 2 12:00 - 15:00 น.',
      'นัดรับวันอังคาร ที่ 2 15:00 - 18:00 น.',
      'นัดรับวันพุธ ที่ 3 09:00 - 12:00 น.',
      'นัดรับวันพุธ ที่ 3 12:00 - 15:00 น.',
      'นัดรับวันพุธ ที่ 3 15:00 - 18:00 น.',
      'นัดรับวันพฤหัสบดี ที่ 4 09:00 - 12:00 น.',
      'นัดรับวันพฤหัสบดี ที่ 4 12:00 - 15:00 น.',
      'นัดรับวันพฤหัสบดี ที่ 4 15:00 - 18:00 น.',
    ]
    const download = ['ดาวโหลด (CSV)']

    return {
      icons: {
        mdiCartMinus,
        mdiMagnify,
        mdiTruck,
        mdiPrinterSearch,
        mdiPencil,
        mdiDelete,
      },
      transport,
      statusFilter,
      changeStatus,
      print,
      transportProduct,
      download,
    }
  },
  data() {
    return {
      serverOptions: {
        page: 1,
        itemsPerPage: 10,
      },
      dialog : false,
      total: 0,
      all: 0,
      name: '',
      status: '',
      OrderStatus: '',
      orders: [],
      valid: true,
      date: [],
      date_payment: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
      transportTypes: [],
      time1: null,
      time2: null,
      time3: null,
      search: '',
      sortBy: '',
      sortDesc: '',
      dataOpenUser: false,
      selected: [],
      dialogDelete: false,
      editedItem: {},
      paymentTime: [
        v => !!v || 'payment time is required.',
      ],
      // date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
      bankShow: store.state.bankShow,
      bankList: store.state.bankList,
      selectBankking: {},
      dateFormatted: this.formatDate(new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10)),
      payment: {},
      menu1: false,
      headers: [
        {
          text: 'เลขที่บิล',
          align: 'start',
          sortable: true,
          value: 'order_inv',
        },
        {text: 'วันที่เปิดบิล', value: 'created_at'},
        {text: 'ผู้สั่งสินค้า', value: 'recipient_name'},
        {text: 'ยอดสุทธิ', value: 'order_expense'},
        // {text: 'ยอดสุทธิ', value: 'order_expense'},
        {text: 'สถานะ', value: 'order_status'},
        {text: 'เครื่องมือ', value: 'actions', sortable: false},
      ],
      OrderStatusPending: {},
      StatusOrder: [],
    }
  },

  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date_payment)
    },
  },

  watch: {
    date(val) {
      this.dateFormatted = this.formatDate(this.date_payment)
    },
    serverOptions: {
      handler() {
        this.getOrders()
      },
    },
    search: {
      handler() {
        this.getOrders()
      },
    },
    status: {
      handler() {
        this.getOrders()
      },
    },
    OrderStatus: {
      handler() {
        this.filterOrder()
      },
    },
  },
  mounted() {
    const date = new Date();
    let day = date.getDate();
    let last_month = date.getMonth();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    this.date.push(`${year}-${String(last_month).padStart(2, '0')}-${day}`);
    this.date.push(`${year}-${String(month).padStart(2, '0')}-${day}`);

    this.getOrders()
    this.getOrderReport()
    this.getStatusOrder()
    this.getBank()
  },
  methods: {
    saveUploadSlip() {
      if (this.$store.state.orderDetil.PT_type === 'DP' && this.$store.state.orderDetil.PT_price_amt > this.$store.state.orderDetil.order_expense - (this.$store.state.orderDetil.order_expense - this.$store.state.orderDetil.amount_deposit)) {
        Swal.fire({
          text: 'จำนวนเงินไม่ถูกต้อง',
          timer: 1500,
          icon: 'error',
          showConfirmButton: false,
          button: false
        })
        return false;
        this.check_error = false;
      }

      if (!this.check_error) {
        var data = {
          order_id: this.$store.state.orderDetil.order_id,
          file: this.$store.state.orderDetil.PT_slip,
          PT_price_amt: this.$store.state.orderDetil.PT_price_amt,
          PT_BankID : this.$store.state.orderDetil.PT_BankID,
          PT_date : this.$store.state.orderDetil.PT_date,
          PT_time : this.$store.state.orderDetil.PT_time,
          user_id: this.$store.state.orderDetil.member_id
        }

        instance_order.uploadSlip(data).then(res => {
          this.$store.state.isLoading = false;
          if (res.data.success) {
            Swal.fire({
              text: 'อัพโหลดหลักฐานสำเร็จ',
              timer: 3000,
              icon: 'success',
              showConfirmButton: false,
              button: false
            })
            this.close()
            location.reload()
          }else{
            Swal.fire({
              icon: 'error',
              title: 'เกิดข้อผิดพลาด !!',
              timer: 3000,
              text: res.data.message,
            })
          }
        }).catch(err => {
          this.$store.state.isLoading = false;
          Swal.fire({
            icon: 'error',
            title: 'เกิดข้อผิดพลาด !!',
            text: err.data.message,
          })
        })
      }
    },
    showOrderMember() {
      // this.orderData = [];
      let $this = this
      orderAPI
        .showOrder(store.state.member.member_id)
        .then(res => {
          if (res.status === 200) {
            this.$store.state.orderDetil = res.data
            this.$router.push({ name : 'pages-order-create' ,params :{ id : this.$store.state.orderDetil.order_id }})
            // if (res.data.order_detail) {
            //   this.$store.state.orderData = res.data.order_detail
            //
            //   if (this.$store.state.orderData && this.$store.state.orderData.length > 0) {
            //     for (var i = 0; i < this.$store.state.orderData.length; i++) {
            //       this.$store.state.orderData[i].changeFile = null
            //     }
            //   }
            //   // $this.orderDetil = res.data
            //   // this.$store.state.orderDetil = $this.orderDetil
            //
            //   if (res.data.order_detail) {
            //     this.$store.state.orderData = res.data.order_detail
            //     // this.$store.commit('setOrderData', res.data.order_detail);
            //   }
            // } else {
            //   this.$store.state.orderData = [];
            // }


          }
          user.removrToken(res)
        })
        .catch(error => {

        })
    },
    getOrderDetail() {
      orderAPI
        .showOrderDetail(this.$store.state.orderDetil.order_id)
        .then(res => {
          if (res.status === 200) {
            if (res.data.order_detail) {
              // $this.orderDetil = res.data
              // this.$store.state.orderDetil = $this.orderDetil
              if (res.data.order_detail) {
                this.$store.state.orderData = res.data.order_detail
              }
              this.$store.state.orderDetil = res.data
            }

          }
          user.removrToken(res)
        })
        .catch(error => {

        })
    },
    customerOpen() {
      this.$store.state.step = 1

      this.$store.state.orderDetil = {}
      this.$store.state.orderData = []
      this.$store.state.member = this.$store.state.user

      this.showOrderMember()
    },
    cancelOrder(item) {
      this.dialogDelete = true
      this.editedItem = item

    },
    editItemAdmin(item) {

      this.$router.push({ name : 'pages-order-detail' ,params :{ id : item.order_id }})
    },
    editItem(item) {
      this.$store.state.member = {};
      this.$store.state.orderDetil = [];
      this.$store.state.orderDetil.order_id = item.order_id;
      this.dialog = true;

      // this.$router.push({ name : 'pages-order-create' ,params :{ id : item.order_id }})

      // <span v-if="item.order_status === 'PT'">รอชำระเงิน</span>
      // <span v-if="item.order_status === 'PC'">รอตรวจสอบ</span>
      orderAPI
        .showOrderDetail(this.$store.state.orderDetil.order_id)
        .then(res => {
          if (res.status === 200) {
            this.$store.state.orderDetil = res.data.data
            if (res.data.data.order_detail) {
              // $this.orderDetil = res.data
              // this.$store.state.orderDetil = $this.orderDetil
              if (res.data.order_detail) {
                this.$store.state.orderData = res.data.data.order_detail
              }

            }

            this.$store.state.step = 1
            this.$store.state.orderDetil.PT_price_amt = null
            this.$store.state.orderDetil.PT_slip = null
            this.$store.state.orderDetil.PT_price_amt = null
            this.$store.state.orderDetil.PT_BankID = null
            this.$store.state.orderDetil.PT_time = null
            // if (this.$store.state.orderDetil.order_status == 'PD') {
            //   this.$store.state.step = 1
            // } else if (this.$store.state.orderDetil.order_status == 'PT') {
            //   this.$store.state.step = 2
            // }  else {
            //   this.$store.state.step = 3
            // }

          }
          user.removrToken(res)
        })
        .catch(error => {

        })
      // this.editedIndex = this.employees_types.indexOf(item)
      // this.editedItem = { ...item }
      // this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.orders.indexOf(item)
      this.editedItem = {...item}
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      instance_order.delete(this.editedItem).then(res => {

        // this.orders.splice(this.editedIndex, 1)
        this.getOrders()
      }).catch(err => {

      })
      this.closeDelete()
    },

    dateMoment(timestamp) {
      return this.$moment(timestamp).format('DD-MMM-YYYY')
    },
    close() {
      this.dialogDelete = false;
      this.dialog = false;
    },
    switchOpenUser(name = null) {
      this.name = name
      this.dataOpenUser = !this.dataOpenUser
    },
    filterOrder() {
      this.$store.state.isLoading = true
      const data = {
        search: this.search ? this.search : '',
        date: this.date ? this.date : '',
        status: this.OrderStatus ? this.OrderStatus : '',
        status2 : '',
        size: this.serverOptions.itemsPerPage,
        page: this.serverOptions.page,
        sortBy : this.sortBy ?? "",
        sortDesc : this.sortDesc ?? ''
      }

      instance_order
        .getOder(data)
        .then(res => {
          if (res.status === 200) {
            this.orders = res.data.data.data
            this.total = res.data.data.total
            this.serverOptions.itemsPerPage = this.serverOptions.itemsPerPage == -1 ? -1 : res.data.data.per_page
            this.serverOptions.page = res.data.data.current_page
          }
          // removrToken
          user.removrToken(res)
          this.$store.state.isLoading = false
        })
        .catch(error => {
          this.$store.state.isLoading = false
        })
    },
    getOrders() {
      this.$store.state.isLoading = true
      const data = {
        search: this.search ? this.search : '',
        date: this.date ? this.date : '',
        status: this.status ? this.status : '',
        status2: '',
        size: this.serverOptions.itemsPerPage,
        page: this.serverOptions.page,
        sortBy : this.sortBy ?? "",
        sortDesc : this.sortDesc ?? ''
      }

      // if (this.date && this.date.length === 2) {
      //   data.date = `${this.$moment(this.date[0]).format('YYYY/M/DD')}-${this.$moment(this.date[1]).format('YYYY/M/DD')}`
      // }
      instance_order
        .getOder(data)
        .then(res => {
          if (res.status === 200) {
            this.getOrderReport(data)
            this.orders = res.data.data.data
            this.total = res.data.data.total
            this.all = res.data.data.total
            this.serverOptions.itemsPerPage = this.serverOptions.itemsPerPage == -1 ? -1 : res.data.data.per_page
            this.serverOptions.page = res.data.data.current_page
          }
          // removrToken
          user.removrToken(res)
          this.$store.state.isLoading = false
        })
        .catch(error => {
          console.log(error)
          this.$store.state.isLoading = false
        })
    },
    getTransportType() {
      const data = {
        search: this.search,
      }
      transportType
        .get(data)
        .then(res => {
          if (res.status === 200) {
            this.transport = res.data.data
          }
          // removrToken
          user.removrToken(res)
        })
        .catch(error => {
          console.log(error)
        })
    },
    getOrderReport(data) {
      this.$store.state.isLoading = true
      if (!data) {
        data = {
          search: this.search,
          date: this.date,
          size: this.serverOptions.itemsPerPage,
          status: this.status,
          status2 : ''
        }
      }
      instance_order
        .getOrderReport(data)
        .then(res => {
          if (res.status === 200) {
            this.OrderStatusPending = res.data.data
          }
          // removrToken
          user.removrToken(res)
          this.$store.state.isLoading = false
        })
        .catch(error => {
          console.log(error)
          this.$store.state.isLoading = false
        })
    },
    async UpdateCancel() {
      // axios.post('<?php echo e(url('api/user/updateOrder')); ?>' + '/' + this.order.order_id,
      let check = false;

      Swal.fire({
        title: "คุณแน่ใจ",
        text: "คุณแน่ใจต้องการยกเลิกการสั่งซื้อใช่หรือไม่!",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        closeOnConfirm: false,

      }).then((result) => { // <--
        if (result.isConfirmed) {
          this.$store.state.isLoading = true;
          var data = {
            order_id: this.editedItem.order_id,
            status: "Y",
            product_amount_comment: this.editedItem.C_order_note_cancel,
          }
          instance_order.orderCancel(data).then(res => {

            this.getOrders()
            this.$store.state.isLoading = false
            this.close()
            Swal.fire({
              text: 'ยกเลิกการสั่งซื้อสำเร็จ',
              timer: 1500,
              icon: 'success',
              showConfirmButton: false,
              button: false
            })
          }).catch(err => {
            this.$store.state.isLoading = false;
            Swal.fire({
              text: err.message,
              timer: 30000,
              icon: 'error',
              showConfirmButton: false,
              button: false
            })
          })

          // axios.post('<?php echo e(url('api/user/updateOrder')); ?>' + '/' + this.order.order_id,
          //   data, config, {}
          // ).then(function (response) {
        }
      });
    },
    getStatusOrder() {
      instance_order
        .getStatusOrder()
        .then(res => {
          if (res.status === 200) {
            this.StatusOrder = res.data.data
            this.StatusOrder.splice(0, -1, {
              label: 'ทั้งหมด',
              value: ''
            })
          }
          user.removrToken(res)
        })
        .catch(error => {
          console.log(error)
        })
    },
    submitValidationPyment() {
      this.$refs.form.resetValidation()
      this.orderUpdatePayment()
    },
    validateForm() {
      console.log('---validateForm----')
      this.orderUpdatePayment()
    },
    formatDate(date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${month}/${day}/${year}`
    },
    parseDate(date) {
      if (!date) return null

      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    clickEditData() {
      // this.buttonStart = true
      // this.buttonGoToNotPay = !this.buttonGoToNotPay
      this.changEditData = true
    },
    getBankShow() {
      // this.payment.PT_BankID
      let PT_BankID = this.$store.state.orderDetil.PT_BankID
      let $this = this

      var data = {
        'order_id' : this.$store.state.orderDetil.order_id,
        'PT_BankID' : PT_BankID
      }
      orderAPI
        .orderUpdatePayment(data)
        .then(res => {
          if (res.status === 200) {
            console.log(this.$store.state.orderData,12354)
            this.getOrderDetail()
            Swal.fire({
              icon: 'success',
              title:'บันทึกข้อมูลเรียบร้อย',
              showConfirmButton: false,
              timer: 1500
            })
          }
          // removrToken
          user.removrToken(res)
        })
        .catch(err => {

          Swal.fire({
            icon: 'error',
            title: err.message,
            showConfirmButton: false,
            timer: 1500
          })
        })
      // if (PT_BankID) {
      //   bankAPI
      //     .show(PT_BankID)
      //     .then(res => {
      //       console.log(res,344)
      //       if (res.status === 200) {
      //         $this.order_detail.bank = res.data
      //         console.log($this.order_detail.bank)
      //       }
      //
      //       // removrToken
      //       user.removrToken(res)
      //     })
      //     .catch(error => {
      //       console.log(error,1234)
      //     })
      // }
      //
      // console.log(this.payment,3333)
    },
    getBank() {
      bankAPI
        .get()
        .then(res => {

          if (res.status === 200) {
            this.bankList = res.data
          }
          // removrToken
          user.removrToken(res)
        })
        .catch(error => {
        })
    },
    // showOrderMember() {
    //   // this.orderData = [];
    //   let $this = this
    //   orderAPI
    //     .showOrder(store.state.member.member_id)
    //     .then(res => {
    //       if (res.status === 200) {
    //         console.log(res.data,222)
    //         $this.payment = res.data
    //       }
    //       // removrToken
    //       user.removrToken(res)
    //     })
    //     .catch(error => {
    //
    //     })
    // },
    orderUpdatePayment() {


      // this.orderData = [];
      var format_date = (this.$store.state.orderDetil.PT_date).split('-')
      this.$store.state.orderDetil.PT_date = format_date[2]+'-'+format_date[1]+'-'+format_date[0]
      orderAPI
        .orderUpdatePayment(this.$store.state.orderDetil)
        .then(res => {
          if (res.status === 200) {

            Swal.fire({
              icon: 'success',
              title:'บันทึกข้อมูลเรียบร้อย',
              showConfirmButton: false,
              timer: 1500
            })
            this.getOrderDetail()
          }
          // removrToken
          user.removrToken(res)
        })
        .catch(err => {

          Swal.fire({
            icon: 'error',
            title: err.message,
            showConfirmButton: false,
            timer: 1500
          })
        })
    },
  },
}
</script>

<style scoped>
.box_content {
  width: 5rem;
  height: 5rem;
  text-align: center;
  background-color: #f4f5f9;
  display: grid;
  align-items: center;
  padding: 10px 0;
  color: #8e8a98;
  font-weight: 600;
  font-size: 16px;
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.box_content.active {
  background-color: #8dbc89;
  color: #ffffff;
}

.bg-header {
  background-color: #8dbc89;
}

.bg-header p {
  color: #f4f5f9;
  font-weight: 600;
  font-size: 16px;
}

.bg-header span {
  color: #fff8ff;
  font-weight: 700;
  font-size: 18px;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  text-decoration: underline;
}

.checkItem {
  width: 15px;
  height: 15px;
  margin-right: 1rem;
}

.picker-res {
  width: 100% !important;
}

@media screen and (max-width: 600px) {
  .test {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }

  .test2 {
    display: grid !important;
  }

  .mg-res {
    margin-top: 1.5rem;
    transform: translateX(-40%);
    left: 50%;
  }

  /* .picker-res {
    width: 100% !important;
  } */
}
</style>
